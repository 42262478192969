import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { companyInfo } from "../constants/companyInfo";

export default function NavBar() {
  return (
    <Box sx={{ py: 1 }}>
      <Container sx={{ my: 1.5 }}>
        <Divider
          color="#FDA228"
          sx={{
            mx: "auto",
            outline: "#fff",
          }}
        >
          <Avatar
            src={companyInfo?.logo}
            variant="rounded"
            sx={{
              mb: 1,
              width: { xs: 185, sm: 300 },
              height: { xs: 45, sm: 50 },
            }}
          />
        </Divider>
      </Container>

      <Stack sx={{ height: { xs: 150, md: 30 }, background: "#0054A6" }}>
        <Container sx={{ py: 0 }}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Stack
              spacing={{ xs: 0, md: 4 }}
              direction={{ xs: "column", md: "row" }}
              alignItems="center"
            >
              <Button
                href={`https://${companyInfo.website}`}
                startIcon={<LanguageIcon />}
                sx={{ textTransform: "lowercase" }}
              >
                www.anchorplc.com
              </Button>
              <Button
                href={`mailto:${companyInfo.email}`}
                startIcon={<MailIcon />}
                sx={{ textTransform: "lowercase" }}
              >
                info@anchorplc.com
              </Button>
              <Button
                href={`tel:${companyInfo.phone}`}
                startIcon={<LocalPhoneIcon />}
              >
                {companyInfo.phoneLabel}
              </Button>
            </Stack>
            <Button
              href={companyInfo.locationUrl}
              startIcon={<LocationOnIcon />}
              sx={{ textTransform: "capitalize" }}
            >
              {companyInfo.location}
            </Button>
          </Stack>
        </Container>
      </Stack>
    </Box>
  );
}
