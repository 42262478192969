import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { companyInfo } from "../constants/companyInfo";
import Title from "./Title";

const Img = styled("img")(({ theme }) => ({
  height: 400,
  [theme.breakpoints.down("md")]: {
    height: 250,
  },
}));

export default function AboutUsSection() {
  return (
    <Box
      sx={{
        backgroundImage:
          "radial-gradient(at center center, #b30b0b 0%, #310202 100%)",
        my: 5,
      }}
    >
      <Container sx={{ py: 7 }}>
        <Stack spacing={5}>
          <Title title="About Us" color="primary" />

          {/* ****** ABOUT ****** */}

          <Stack
            spacing={{ xs: 1, sm: 15 }}
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Img src={companyInfo.constructionPic} />

            <Stack pt={5}>
              <Typography variant="subtitle1" color="#fff">
                {companyInfo?.aboutUs}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                sx={{ width: 150, mt: 7 }}
                href={`https://${companyInfo.website}`}
              >
                Visit Us
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
