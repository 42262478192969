import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Title from "./Title";

const StyledBox = styled("Box")(({ theme }) => ({
  width: "30%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  border: "5px solid #0054a6",
  padding: theme.spacing(3),
  borderRadius: 20,
  position: "relative",
}));

export default function StepsSection() {
  return (
    <Container sx={{ my: 10 }}>
      <Stack spacing={5}>
        <Stack spacing={3}>
          <Title title="Steps" color="secondary" />

          <Typography variant="p" gutterBottom mb={4}>
            Easy to get started
          </Typography>
        </Stack>

        <Stack
          spacing={5}
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
        >
          <StyledBox>
            <Typography
              variant="h4"
              gutterBottom
              color="secondary"
              sx={{
                position: "absolute",
                top: "-15%",
                background: "#F1F1F1",
                px: 1,
              }}
            >
              01
            </Typography>

            <Typography variant="h5" gutterBottom mt={1}>
              Register
            </Typography>
            <Typography variant="body2">
              Create an account with us using your username
            </Typography>
          </StyledBox>
          <StyledBox>
            <Typography
              variant="h4"
              gutterBottom
              color="secondary"
              sx={{
                position: "absolute",
                top: "-15%",
                background: "#F1F1F1",
                px: 1,
              }}
            >
              02
            </Typography>

            <Typography variant="h5" gutterBottom mt={1}>
              Login
            </Typography>
            <Typography variant="body2">
              Log in to the software website.
            </Typography>
          </StyledBox>
          <StyledBox>
            <Typography
              variant="h4"
              gutterBottom
              color="secondary"
              sx={{
                position: "absolute",
                top: "-15%",
                background: "#F1F1F1",
                px: 1,
              }}
            >
              03
            </Typography>

            <Typography variant="h5" gutterBottom mt={1}>
              Explore Role
            </Typography>
            <Typography variant="body2">You can start your project.</Typography>
          </StyledBox>
        </Stack>
      </Stack>
    </Container>
  );
}
