import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

export default function Title({ title, color }) {
  return (
    <Divider sx={{ width: "20%" }}>
      <Typography variant="h4" color={color}>
        {title}
      </Typography>
    </Divider>
  );
}
