import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

import meselalLogo from "../assets/meselal_logo.png";

const footerStyle = {
  // background: "hsla(205, 46%, 30%, 1)",
  // background:
  //   "linear-gradient(90deg, hsla(205, 46%, 30%, 1) 0%, hsla(260, 29%, 36%, 1) 100%)",
  background: "#8e0404",
  backgroundImage:
    "radial-gradient(at center center, #b30b0b 0%, #310202 100%)",
  py: 5,
  color: "#fff",
};

const Img = styled("img")(({ theme }) => ({
  height: 50,
  marginBottom: 3,
}));

export default function FooterSection() {
  return (
    <Box sx={footerStyle}>
      <Container>
        <Stack spacing={5}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack spacing={2}>
              <a
                href="https://www.meselaltech.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img src={meselalLogo} />
              </a>
              <Typography
                variant="p"
                component="div"
                sx={{ maxWidth: { xs: "100%", md: "50%" } }}
              >
                We develop custom software solutions as per the requirement of
                our customers, with the modern design principles and the latest
                tools.
              </Typography>

              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <IconButton color="primary" href="tel:+251911526759">
                  <LocalPhoneIcon />
                </IconButton>
                <IconButton color="primary" href="mailto:meselaltech@gmail.com">
                  <EmailIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  href="https://facebook.com/meselalTechUnit"
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  href="https://linkedin.com/in/meselal-techunit-050591240"
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  href="https://goo.gl/maps/H3QNh3uohLfJKLP89"
                >
                  <LocationOnIcon />
                </IconButton>
              </Stack>
            </Stack>
            <Typography variant="p" sx={{ mt: { xs: 4, md: 0 } }}>
              &copy; 2022{" "}
              <a
                href="https://www.meselaltech.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#0070CC" }}
              >
                Meselal Technologies
              </a>{" "}
              PLC. All Rights Reserved.
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
