import logo from "../assets/logo.jpg";
import constructionPic from "../assets/construction_pic_1.jpg";

export const companyInfo = {
  logo,
  constructionPic,
  website: "www.anchorplc.com",
  email: "info@anchorplc.com",
  phone: "+251935400945",
  phoneLabel: "+2519 35 40 09 45",
  location: "Wolo sefer Bamacon Building 6th Floor",
  locationUrl:
    "https://www.google.com/maps?ll=8.992342,38.77505&z=16&t=m&hl=en&gl=ET&mapclient=embed&cid=12798093598542816381",
  aboutUs:
    "Anchor Foundation specialists strives to provide its clients reliable quality service and cost effective underground solution by implementing up to date technologies and construction methods. AFS will fully engage its resource and knowledge to create a continuous learning platform from the day to day activities to develop local solutions and local construction inputs.",
};

export const PROJECTS_BASE_URL = "anchorerp.org";
